<template>
  <div>
    <ButtonIcon
      class="rounded-full border border-neutral-400"
      variant="simple"
      :disabled="swiper.isBeginning"
      :title="$t('swiper.previous')"
      :icon="{ name: 'arrow-left', color: 'black', size: 'lg' }"
      @click="swiper.slidePrev()"
    />
    <ButtonIcon
      variant="simple"
      :disabled="swiper.isEnd"
      :title="$t('swiper.next')"
      class="ml-6 rounded-full border border-neutral-400"
      :icon="{ name: 'arrow-right', color: 'black', size: 'lg' }"
      @click="swiper.slideNext()"
    />
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const swiper = useSwiper()
</script>
